import { ChangeEvent, FC, FormEvent, KeyboardEvent, useRef, useState } from 'react';

import { Icon } from '@common/atoms/Icon';
import { useTranslation } from '@pxr/i18n';

import styles from './ExpandableSearchBar.module.scss';

export type ExpandableSearchBarVariant = 'default' | 'small';

export interface Props {
    additionalClass?: string;
    currentSearch?: string;
    isInverted?: boolean;
    onChange?: (searchText: string) => void;
    onExpand?: () => void;
    onSubmit?: (searchText: string) => void;
    variant?: ExpandableSearchBarVariant;
}

export const ExpandableSearchBar: FC<Props> = ({
    additionalClass = '',
    currentSearch = '',
    isInverted,
    onChange,
    onExpand,
    onSubmit,
    variant = 'default',
}) => {
    const __meta = useTranslation('meta').t;

    const [expanded, setExpanded] = useState(!!currentSearch);
    const [searchText, setSearchText] = useState(currentSearch);

    const formRef = useRef<HTMLFormElement | null>(null);

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newText = e.target.value;
        setSearchText(newText);
        onChange && onChange(newText);
    };

    const clearSearchText = () => {
        setSearchText('');
        setExpanded(false);
        onExpand && onExpand();
    };

    const handleSearchSubmit = (e: FormEvent) => {
        e.preventDefault();
        formRef.current && formRef.current.submit();
        onSubmit && onSubmit(searchText);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        switch (e.key) {
            case 'Enter':
                e.preventDefault();
                handleSearchSubmit(e as FormEvent);
                break;
            case 'Escape':
                e.preventDefault();
                clearSearchText();
                break;
        }
    };

    const handleFocus = () => {
        setExpanded(true);
        onExpand && onExpand();
    };

    const handleBlur = () => {
        setExpanded(!!searchText);
        onExpand && onExpand();
    };

    const classes = [styles.ExpandableSearchBar, styles[variant], additionalClass];
    if (expanded) classes.push(styles.expanded);
    if (isInverted) classes.push(styles['is-inverted']);

    return (
        <div className={classes.join(' ')}>
            <span className={styles['search-icon']}>
                <Icon.search />
            </span>
            <input
                type="text"
                name="q"
                className={styles['search-input']}
                value={searchText}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder={variant !== 'small' ? __meta('label.search') : ''}
            />
            {expanded ? (
                <span className={styles['clear-search']} onClick={clearSearchText}>
                    <Icon.cross />
                </span>
            ) : null}
        </div>
    );
};

export default ExpandableSearchBar;
